import React from 'react';
import styled from 'styled-components';

const TermsWrapper = styled.div`
  margin: 50px auto;
  max-width: 90%;
  padding: 20px;
  color: #fff;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffba00;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666666;

  strong {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CookiePolicy: React.FC = () => {
  return (
    <TermsWrapper>
      <Paragraph>
      Last updated on <span style={{color:'#784dcc'}}>November 1st 2024</span><br/><br/>

The content on www.tradesimple.ca is intended solely for educational and informational purposes. No part of this website or its content constitutes legal financial advice, nor should it be interpreted as such. Use and interpretation of the information are at your own risk, and Trade Simple is not liable for any losses resulting from such investments. All information is provided “as is,” without any warranty. Trading is not a “get rich quick” scheme; it involves risk and may not be suitable for everyone. Some information on the website may come from sources not affiliated with Trade Simple. By accessing this information, you acknowledge your understanding and consent to these terms and release Trade Simple from liability.<br/><br/>
Trade Simple ("Company") is not an investment advisory service, nor a registered investment advisor or broker-dealer. The Company does not provide specific recommendations or advice on which securities or currencies customers should buy or sell. All trading decisions are the sole responsibility of the individual customer.<br/><br/>
U.S. Government Required Disclaimer<br/><br/>

Commodity Futures Trading Commission.*Futures, options, and spot currency trading have large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest in the futures and options markets. Don’t trade with money you can’t afford to lose. This website is neither a solicitation nor an offer to Buy/Sell futures or options. No representation is being made that any account will or is likely to achieve profits or losses similar to those discussed on this website. The past performance of any trading system or methodology is not necessarily indicative of future results.<br/><br/>

CFTC RULE 4.41<br/><br/>

HYPOTHETICAL OR SIMULATED PERFORMANCE RESULTS HAVE CERTAIN LIMITATIONS. UNLIKE AN ACTUAL PERFORMANCE RECORD, SIMULATED RESULTS DO NOT REPRESENT ACTUAL TRADING. ALSO, SINCE THE TRADES HAVE NOT BEEN EXECUTED, THE RESULTS MAY HAVE UNDER-OR-OVER COMPENSATED FOR THE IMPACT, IF ANY, OF CERTAIN MARKET FACTORS, SUCH AS LACK OF LIQUIDITY. SIMULATED TRADING PROGRAMS IN GENERAL ARE ALSO SUBJECT TO THE FACT THAT THEY ARE DESIGNED WITH THE BENEFIT OF HINDSIGHT. NO REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFIT OR LOSSES SIMILAR TO THOSE SHOWN.<br/><br/>

Futures trading involves substantial risk and may not be suitable for all investors. An investor could potentially lose the entire investment or even more. Risk capital refers to funds that can be lost without endangering one’s financial security or lifestyle. Only risk capital should be used for trading, and only individuals with adequate risk capital should consider trading. Past performance does not necessarily predict future results.<br/><br/>
        </Paragraph>
    </TermsWrapper>
  );
};

export default CookiePolicy;
