import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faArrowDownLong, faGraduationCap, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { FaIdBadge } from 'react-icons/fa';

import styled from 'styled-components';

const MainWrapper = styled.div`
margin: 50px 0px 0px;
max-width:100%;
border-radius:20px;
padding:20px;

p{
  text-align:center;
  font-size:17px;
  color:#a0a0a0;
  margin:0 30%;
  @media (max-width: 768px) {
    margin:0 0%;
    font-size:14px;
    margin-top:10px;
  }
}

h1{
  text-align:center;
  color:#000;
  font-weight:500;
  font-size:45px;
  font-family: var(--font-bold);
  @media (max-width: 768px) {
    font-size:22px;
  }
}
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 30px;
  }

  .auditkyc {
    border-radius: 15px;
    background-color: #f4f4f4;
    padding: 25px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    .icon-container {
      width: 100%;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 40px;
        color: #784dcc;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: center;
      margin-top: 10px;
    }

    .description {
      padding: 0;
      margin: 0;
      text-align: left;
      font-size: 13px;
      margin-top: 6px;
    }

    .explore-link {
      display: flex;
      align-items: center;
      transition: transform 0.3s ease, color 0.3s ease;

      &:hover {
        transform: translateY(-3px);
        color: #ffba00;
      }

      .arrow {
        width: 20px;
        height: 20px;
        margin-bottom: 0;
        transition: transform 0.3s ease;
      }

      &:hover .arrow {
        transform: translateX(5px);
      }
    }
  }

  img {
    margin-bottom: 20px;
    width: 160px;
    height: 160px;
  }
`;

const BlockThree: React.FC = () => {
  return (
    <div className="blockthree-wrapper apoplsp" style={{ margin: 0 }}>
      <MainWrapper>
        <h1>Who Can Use <span style={{ color: '#784dcc' }}>Trade Simple?</span></h1>
        <p>Trade Simple offers a secure platform for professionals, students, job seekers, and business owners to invest, learn, and grow.</p>
        <Audits>
          <div className="auditkyc">
            <div className="icon-container">
              <FontAwesomeIcon icon={faUserTie} className="icon" />
            </div>
            <h1 className="title">Working Professionals</h1>
            <p className="description">Trade Simple empowers professionals with seamless tools and solutions designed specifically for managing and optimizing their day trading activities.
</p>
          </div>

          <div className="auditkyc">
            <div className="icon-container">
            <FaIdBadge className='icon' />
            </div>
            <h1 className="title">Job Seekers</h1>
            <p className="description">Job seekers can turn to Trade Simple to master the art of day trading and gaining valuable experience in the fast-moving financial markets.
</p>
          </div>

          <div className="auditkyc">
            <div className="icon-container">
              <FontAwesomeIcon icon={faGraduationCap} className="icon" />
            </div>
            <h1 className="title">Students</h1>
            <p className="description">Students can explore the fundamentals of day trading and gain practical experience in financial markets while learning about investment strategies and market analysis.
</p>
          </div>

          <div className="auditkyc">
            <div className="icon-container">
              <FontAwesomeIcon icon={faBriefcase} className="icon" />
            </div>
            <h1 className="title">Business People</h1>
            <p className="description">Business owners can use Trade Simple to streamline their day trading activities, manage investments efficiently, and optimize their financial strategies for better market performance.</p>
          </div>
        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
