import React, { useEffect, useState, useRef } from 'react';
import { FaUserGraduate, FaTrophy, FaClock } from 'react-icons/fa'; // Import Font Awesome icons
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  
  border-radius: 20px;
  height: 20vh; /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: fit-content; /* Fixed height */
  }

  p {
    text-align: center;
    font-size: 17px;
    color: #ffba00;
  }

  h1 {
    text-align: center;
    color: #fff;
    font-size: 55px;
    font-family: var(--font-bold);
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }
`;

const StatsWrapper = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const StatCard = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    height: 50%; /* Half-height */
    width: 2px;
    background-color: #ddd;
    transform: translateY(-50%);
  }

  h3 {
    color: #bbb;
    font-size: 16px;
    margin: 10px 0;
  }

  p {
    color: #784dcc;
    font-family: var(--font-bold);
    font-size: 28px;
    animation: fadeIn 2s ease-in-out;
  }

  svg {
    width: 50px;
    height: 50px;
    color: #ffba00;
  }

  @media (max-width: 768px) {
    padding: 7px;

    svg {
      width: 40px;
      height: 40px;
    }

    &:not(:last-child)::after {
      top: unset; /* Reset top */
      right: unset; /* Reset right */
      bottom: 0; /* Move to bottom */
      left: 50%; /* Center horizontally */
      height: 2px; /* Change height to border thickness */
      width: 70%; /* Half-width */
      transform: translateX(-50%); /* Center the border */
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;


const BlockThree: React.FC = () => {
  const [statsVisible, setStatsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStatsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, []);

  const animatedNumbers = [
    { icon: <FaClock />, title: 'Yrs of Experience', endValue: 6, suffix: '+' },
    { icon: <FaTrophy />, title: 'Success Rate', endValue: 98, suffix: '%' },
    { icon: <FaUserGraduate />, title: 'Students Trained', endValue: 1000, suffix: '+' },
  ];

  return (
    <MainWrapper ref={wrapperRef}>
      <StatsWrapper>
        {animatedNumbers.map((stat, index) => (
          <StatCard key={index}>
            {stat.icon}
            <h3>{stat.title}</h3>
            <p>
              {statsVisible ? (
                <NumberCounter value={stat.endValue} suffix={stat.suffix} />
              ) : (
                '0'
              )}
            </p>
          </StatCard>
        ))}
      </StatsWrapper>
    </MainWrapper>
  );
};

const NumberCounter: React.FC<{ value: number; suffix?: string }> = ({ value, suffix = '' }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 2000; // Animation duration in ms
    const increment = value / (duration / 10);

    const interval = setInterval(() => {
      start += increment;
      if (start >= value) {
        clearInterval(interval);
        setCurrentValue(value);
      } else {
        setCurrentValue(Math.floor(start));
      }
    }, 10);

    return () => clearInterval(interval);
  }, [value]);

  return (
    <span>
      {currentValue}
      {suffix}
    </span>
  );
};

export default BlockThree;
