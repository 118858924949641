import React from 'react';
import styled from 'styled-components';

const TermsWrapper = styled.div`
  margin: 50px auto;
  max-width: 90%;
  padding: 20px;
  color: #000;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffba00;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666666;

  strong {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TermsOfUse: React.FC = () => {
  return (
    <TermsWrapper>
      <Paragraph>
     Last updated on  <span style={{color:'#784dcc'}}>November 1st 2023</span><br/>

In order to utilize our website, you must accept these terms and conditions, which you must read in its entirety.  These Terms and Conditions of Service and Use for www.tradesimple.ca (the “Terms and Conditions”) are effective as of November 1, 2024, by Trade Simple (“Trade Simple,” “www.tradesimple.ca,” “we,” “us,” or “our”). Without waiving or releasing any rights or obligations under any previous terms and conditions for using www.tradesimple.ca, these Terms and Conditions hereby amend and restate any prior terms and conditions.<br/><br/>

www.tradesimple.ca, along with all associated content, materials, and services (collectively referred to as the “Site”), serves as an online resource focused on securities trading. It offers customers and members interactive chat rooms, trading opportunities, as well as educational classes, materials, and resources to assist them in their trading activities.<br/><br/>
In exchange for each member or customer’s (each referred to as a “customer” or “you” or “member” or “subscriber”) access to and use of the Site, we require all members and customers to act with integrity, adhere to our Site rules, and comply with these Terms and Conditions as well as any other rules, regulations, or policies set forth by Trade Simple.<br/><br/>
You agree to read these Terms and Conditions thoroughly and keep them for your future reference. You understand, acknowledge, and agree that these Terms and Conditions govern all aspects of your use of the Site and the products and services offered by Trade Simple. By agreeing to these Terms and by using and accessing the Site, you confirm that you have received, read, and understood these Terms and Conditions as well as the Privacy Policy of Trade Simple.<br/><br/>

If you do not agree to be bound by these Terms and Conditions, you should not click “I AGREE,” become a paid member, or use or access the Site. YOU UNDERSTAND, ACKNOWLEDGE, AND AGREE THAT CLICKING “I AGREE” OR USING THE SITE AFTER BECOMING A PAID MEMBER IS THE LEGAL EQUIVALENT OF YOUR MANUAL SIGNATURE ON THESE TERMS AND CONDITIONS, AND THAT YOU WILL BE LEGALLY BOUND BY THEM.<br/><br/>

Without limiting any previous agreements or arrangements, you acknowledge and agree to the following:<br/><br/>

The materials, services, content, and other intellectual property included on this Site are owned by Trade Simple and are protected by copyright, patent, trademark, and similar laws.<br/><br/>
There is a strict NO REFUND policy. You may request to cancel your subscription at any time by emailing tradesimple45@gmail.com. accuracies or errors to the fullest extent permitted by law.<br/><br/>
Deciding to buy or sell any futures, stocks, options, or other securities based on alerts is entirely your choice. There is no pressure or requirement to follow them. You are solely responsible for your trades, including any profits or losses. Trade Simple holds no liability for your trading results.<br/><br/>
Hate speech directed toward the host or any other person will not be tolerated. In such cases, you will be removed from the site, your subscription will be canceled without a refund, and your live access will be revoked.<br/><br/>
You are strictly prohibited from embedding, broadcasting, or recording this live content anywhere else<br/><br/>
The information and content on this site and related websites is for informational and educational use only. It should not be interpreted as investment or trading advice, nor as an offer or recommendation to buy, sell, or hold any financial positions.<br/><br/>
No data, content, or information provided by Trade Simple should be considered advice or a recommendation to buy or sell securities, nor as an offer or solicitation to trade any securities. Trade Simple is not registered as an investment advisor with the U.S. Securities and Exchange Commission or the Canada Securities Commission.<br/><br/>
You are responsible for understanding the risks involved in trading futures, options, stocks, and foreign exchange products. Before trading these securities, please review the Characteristics and Risks of Standardized Options and the Risk Disclosure for Futures and Options.<br/><br/>
We provide links to Web sites outside of our web sites, as well as to third party Web sites. These linked sites are not under our control, and we cannot accept responsibility for the conduct of companies linked to our website. Before disclosing your personal information on any other website, we advise you to examine the terms and conditions of using that Web site and its privacy statement.<br/><br/>
This Website is not intended for visitors that are minors or under eighteen (18) years of age. Trade Simple does not knowingly solicit or collect information from individuals under the age of eighteen (18). If we determine that we have inadvertently collected personal information from someone under eighteen (18) years of age, we will take reasonable steps to purge this information from our database. We encourage parents and guardians to spend time online with their children and to participate and monitor the Internet activities of their children.<br/><br/>
The Site may use cookie and tracking technology depending on the features offered. Cookie and tracking technology are useful for gathering information such as browser type and operating system, tracking the number of visitors to the Site, and understanding how visitors use the Site. Cookies can also help customize the Site for visitors.<br/><br/>
When purchasing a subscription, membership, or other recurring fee-based services on the Site, a valid credit card is required for billing purposes. By doing so, you consent to, acknowledge, and permit the website or a third-party payment vendor on behalf of Trade Simple to store this payment information. You agree to the following policies and procedures regarding the payment of subscription, membership, or other recurring fees associated with the Site.<br/><br/>
All payments made to Trade Simple for any product, account, or service, including renewals, are non-refundable. Trade Simple is under no obligation to offer refunds or credits for any reason, including dissatisfaction or failure to cancel your account or service before its automatic renewal. In no circumstance will you be entitled to, nor is Trade Simple required to provide, a refund or credit for any reason, including dissatisfaction or failure to cancel before automatic renewal.<br/><br/>
All fees charged by Trade Simple for any product, account, or service on the Site are exclusive of any taxes, levies, or duties imposed by any taxing authority. You are, and hereby agree to be, responsible for paying all such taxes, levies, or duties that may arise from your purchase of products or use of the Site.<br/><br/>
Your account or service may be canceled at any time, subject to these terms; however, as noted above, no refunds will be issued if your account or any periodic fee-based service is canceled before the end of the applicable period or term. Cancellation requests via telephone, facsimile, or other forms of communication will not be accepted, honored, or effective. Canceling your account or service will result in the immediate deactivation and/or deletion of your account or service, denial of access to the Site, and the forfeiture of all content and information associated with your account or service. Once canceled, no data, content, or information can be recovered, and you bear full responsibility for preserving any data, content, or information prior to cancellation. Trade Simple may retain data, content, or information from your account in backup or archival copies of the Site and databases, though such copies, if they exist, will not be accessible to you.<br/><br/>
Trade Simple, along with its directors, officers, shareholders, employees, agents, and affiliates, shall not be liable to you, under any legal or equitable theory, for (a) any consequential, incidental, punitive, exemplary, special, or indirect damages of any kind, including, but not limited to, lost profits, revenues, or business, arising out of, related to, or connected with these terms and conditions, your use of the Site, or the products or services, whether or not advised of the possibility of such damages.​<br/><br/>
Trade Simple reserves the right to modify these Terms and Conditions at any time without prior notice. By using the Site, you agree to be bound by the most current version of these Terms and Conditions, and your continued use of the Site reaffirms your acceptance of the latest Terms and Conditions. You agree to check the Site regularly for updates to these Terms and Conditions.<br/><br/>
We as a merchant shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time.<br/><br/>
        </Paragraph>
    </TermsWrapper>
  );
};

export default TermsOfUse;
