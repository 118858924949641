import React, { useState, useEffect } from "react";
import copy from "../../assets/images/logo.png";
import { useSnackbar } from 'notistack';

import "./Footer.scss";

const Footer: React.FC = () => {


  return (
    <div className="footer-wrapper ">
      <div className="footer-grid">
     <div>
      <img src={copy} style={{width:'40%'}}/>
        <div
            style={{
              fontSize: 16,
              padding: "0px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <p style={{ fontWeight: 400,              fontSize: 14,
 color: "rgb(102, 102, 102)" }}>
  Trade Simple: Your trusted platform for learning day trading and mastering day trading strategies.


            </p>
          </div>
          <div className="media flex-item">
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 59%)",
                }}
              >
                <a href="https://x.com/TradeSimple45" style={{ color: '#784dcc'}}>
                  <i className="fab fa-x-twitter"></i>
                </a>
              </div>
              <div
                style={{
                  zIndex:1000,
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 59%)",
                }}
              >
                <a href="https://t.me/tradesimple45" style={{ color: '#784dcc'}}>
                  <i className="fab fa-telegram"></i>
                </a>
              </div>

              <div
                style={{
                  zIndex:1000,
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 59%)",
                }}
              >
                <a href="https://wa.me/+12267981010" style={{ color: '#784dcc'}}>
                  <i className="fab fa-whatsapp"></i>
                </a>
              </div>
            
            
              <div
                style={{
                  zIndex:1000,
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 59%)",
                }}
              >
                <a href="https://www.tiktok.com/@tradesimple45" style={{ color: '#784dcc'}}>
                  <i className="fab fa-tiktok"></i>
                </a>
              </div>

              <div
                style={{
                  zIndex:1000,
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 59%)",
                }}
              >
                <a href="https://www.instagram.com/tradesimple45/" style={{ color: '#784dcc'}}>
                  <i className="fab fa-instagram"></i>
                </a>
              </div>

              <div
                style={{
                  zIndex:1000,
                  width: 40,
                  height: 40,
                  borderRadius: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgb(211 211 211 / 59%)",
                }}
              >
                <a href="https://www.facebook.com/profile.php?id=100077561889412" style={{ color: '#784dcc'}}>
                  <i className="fab fa-facebook"></i>
                </a>
              </div>

             
            
            </div>
     </div>
<div className="mon-no"></div>
        <div>
          <p style={{ fontSize: 14 }}>COMPANY</p>
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="./about">
              About
            </a>
          </div>
       
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="./#why">
              Benefits
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="./#plans">
              Plans
            </a>
          </div>
        </div>

        <div>
          <p style={{ fontSize: 14 }}>SUPPORT</p>
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="mailto:tradesimple45@gmail.com">
              Email Support
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="https://t.me/tradesimple45">
              Telegram Support
            </a>
          </div>
         
         
        </div>
   

        <div>
          <p style={{ fontSize: 14 }}>OTHERS</p>
          
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="./terms-of-use">
              Terms of Use
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="./disclaimer">
              Disclaimer
            </a>
          </div>
         
        </div>

        
           <div>
          {/* <p style={{ fontSize: 14 }}>PLATFORM</p>
          <div
            style={{
              fontSize: 14,
              padding: "20px 0px 5px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="/">
              Terms
            </a>
          </div>
          <div
            style={{
              fontSize: 14,
              padding: "5px 0px",
              fontFamily: "var(--font-regular)",
            }}
          >
            {" "}
            <a style={{ fontWeight: 400, color: "rgb(102, 102, 102)" }} href="/">
              Privacy
            </a>
          </div> */}
        </div>

   
      </div>

      <hr className="custom-hr" />
      <div className="mx">
        <div className="footer-container">
          <div className="footer-content flex-item">
            <div className="footer-second-content flex-item">
              {/* <div className="app-icon flex-item">
                <AppStoreIcon />
                <GooglePayIcon />
              </div> */}

              <div>
                <p style={{ color: "black", fontSize: 13.5 }}>
                  ©2024 <a href="#" style={{color:'#784dcc'}}>Trade Simple</a>
                </p>
             
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
