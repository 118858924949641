import React, { useState } from "react";
import styled from "styled-components";
import Hero from "../../components/Hero";
import BlockTwo from '../../components/BlockTwo';
import BlockThree from "../../components/BlockThree";
import BlockFour from "../../components/BlockFour";
import BlockFive from "../../components/BlockFive";
import BlockSix from "../../components/BlockSix";
import BlockSeven from "../../components/BlockSeven";
import BlockEight from "../../components/BlockEight";
import BlockNine from "../../components/BlockNine"
import BlockTen from "../../components/BlockTen"
import BlockEleven from "../../components/BlockEleven"
import BlockSeventeen from "../../components/BlockSeventeen"
import BlockEighteen from "../../components/BlockEighteen"
import About from "../../components/About"

import BlockTwentyTwo from "../../components/BlockTwentyTwo"



const Home: React.FC = () => {

  
 
  return (
    <div>
      <div>
      <div id="hero">
          <Hero />
        </div>   
  
        <BlockFive/>

        <div id="why">
          <BlockThree />
        </div>        <div id="plans"><BlockSeven/></div>
 <BlockEight/> <BlockNine/>
     {/* <TransactionRow />
<AuditHome /> */}
      </div>
      </div>
  );
};

export default Home;
