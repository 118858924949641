import React from "react";
import styled, { keyframes } from "styled-components";

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const MainWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 30px;

  h1 {
    text-align: center;
    color: #000;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
    font-size: 17px;
    color: #a0a0a0;
    margin: 0 30%;

    @media (max-width: 768px) {
      margin: 0 10%;
      font-size: 13px;
    }
  }
`;

const PricingContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 80px;
  justify-content: center;
  flex-wrap: wrap;
  perspective: 1000px;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const PricingCard = styled.div<{ popular?: boolean }>`
  width: 380px;
  padding: 2.5rem;
  border-radius: 24px;
  background: linear-gradient(90deg, rgba(144, 109, 255, 0.2), rgba(160, 85, 240, 0.2));
  backdrop-filter: blur(20px);
  border: 2px solid rgb(160, 85, 240);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  transform-style: preserve-3d;

  &:hover {
    transform: translateY(-10px);
    border: 2px solid rgb(160, 85, 240);
    box-shadow: 0 0 20px rgba(103, 132, 255, 0.1), 0 0 40px rgba(103, 132, 255, 0.1);
  }

  @media (max-width: 768px) {
    width: 90%;
    border-radius: 14px;

    padding: 1.5rem;
  }
`;

const InnerGlow = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 24px;
  background: radial-gradient(circle at 50% 50%, rgba(103, 132, 255, 0.15) 0%, transparent 70%);
  pointer-events: none;
`;

const PlanIcon = styled.div`
  background: linear-gradient(135deg, rgba(103, 132, 255, 0.2), rgba(103, 132, 255, 0.1));
  width: 56px;
  height: 56px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    animation: shine 2s infinite;
  }

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
    font-size: 1.25rem;
  }
`;

const PlanName = styled.h2`
  color: #3f0063;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Price = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin: 1.5rem 0;
  color: #3f0063;
  line-height: 20px;
  align-items: baseline;
  gap: 0.5rem;

  span {
    font-size: 1rem;
    opacity: 0.8;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin: 1rem 0;
  }
`;

const Features = styled.ul`
  list-style: none;
  margin: 2rem 0;

  li {
    margin: 1rem 0;
    opacity: 0.9;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: rgb(102, 102, 102);
    font-size: 1.1rem;
    @media (max-width: 768px) {
      font-size: 0.7rem;

    }

    &::before {
      content: "-";
      color: #3f0063;
      font-size: 1.1rem;
      @media (max-width: 768px) {
        font-size: 0.7rem;
  
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    li {
      margin: 0.5rem 0;
    }
  }
`;

const CTAButton = styled.a`
  width: 100%;
  padding: 16px 30px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(145deg, rgb(123, 59, 202), rgb(168, 91, 242));
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  @media (max-width: 768px) {
    padding: 12px 20px;
    font-size: 1rem;
  }
`;

const Comparison = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.975rem;
  margin-top: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const Highlight = styled.div`
  position: absolute;
  top: 1rem;
  right: -4rem;
  background: rgb(160, 85, 240);
  color: white;
  padding: 0.5rem 4rem;
  transform: rotate(45deg);
  font-size: 0.875rem;
  font-weight: 500;
  box-shadow: 0 2px 10px rgba(103, 132, 255, 0.3);

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const Pricing: React.FC = () => {
  return (
    <>
      <MainWrapper>
        <h1>
          Our <span style={{ color: "#784dcc" }}>Plans</span>
        </h1>
        <p>
          Here’s how you can adapt the content for your trading course
        </p>
      </MainWrapper>
      <PricingContainer>
        {/* Full Trading Course Plan */}
        <PricingCard>
          <InnerGlow />
          <PlanIcon>📚</PlanIcon>
          <PlanName>Full Trading Course</PlanName>
          <Price>
            CAD $999<span>/One Time Payment</span>
          </Price>
          <Features>
            <li>Full Trading Course</li>
            <li>Technical Analysis</li>
            <li>Chart Set Up</li>
            <li>Indicators Set Up</li>
            <li>How to Use Broker</li>
            <li>5 Groundbreaking Strategies</li>
            <li>Strategies for Futures, Options, Stocks, Forex, and Crypto</li>
            <li>Telegram Group for All Trade Simple Members</li>
          </Features>
          <CTAButton href="https://users.tradesimple.ca/subscribe">Get Started</CTAButton>
        </PricingCard>

        {/* Live Trading Futures Plan */}
        <PricingCard popular>
          <Highlight>POPULAR</Highlight>
          <InnerGlow />
          <PlanIcon>⚡</PlanIcon>
          <PlanName>Live Trading Futures</PlanName>
          <Price>
            CAD $249<span>/Month (auto renewal)</span>
          </Price>
          <Features>
            <li>Live Futures Trading</li>
            <li>Real-Time Trade Alerts</li>
            <li>Live Technical Analysis Before Market Opens</li>
            <li>Live Mentoring</li>
            <li>Trade Review</li>
            <li>Live Q&A Every Evening</li>
            <li>Guidance in Punjabi</li>
            <li>Private Telegram Group for LIVE Trading Members</li>
          </Features>
          <CTAButton href="https://users.tradesimple.ca/subscribe">Get Started</CTAButton>
        </PricingCard>

        {/* One-On-One Session Plan */}
        <PricingCard>
          <InnerGlow />
          <PlanIcon>🎓</PlanIcon>
          <PlanName>One-On-One Session</PlanName>
          <Price style={{lineHeight:'35px'}}>
            CAD $99<span>/45 Minutes</span> or <br /> CAD $149<span>/90 Minutes</span>
          </Price>
          <Features>
            <li>One-on-One Strategies Review</li>
            <li>Answer to All Your Questions</li>
            <li>Perfect for Beginners in Trading</li>
          </Features>
          <CTAButton href="https://users.tradesimple.ca/subscribe">Get Started</CTAButton>
        </PricingCard>
      </PricingContainer>
    </>
  );
};

export default Pricing;
