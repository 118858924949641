import React, { useState } from 'react';
import styled from 'styled-components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <PanelWrapper
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <TabContent>{children}</TabContent>}
    </PanelWrapper>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const TradingLevels: React.FC = () => {
  const [value, setValue] = useState(0);

  return (
    <Container>
      <GridContainer>
        <DescriptionColumn>
          <Header>Know about our <span style={{color:'rgb(120, 77, 204)'}}>Trading Levels</span></Header>
        <p style={{color:'rgb(102, 102, 102)',fontSize:15}}>Take our in-depth market knowledge test for free! We assess your trading proficiency to help you determine which course level is the best fit for you.
<br/><br/>
By utilizing insights from your test results, you can choose the most suitable program to enhance your trading skills.</p>
        </DescriptionColumn>

        <ButtonsColumn>
        <TabsContainer>
            <Tabs>
              <Tab onClick={() => setValue(0)} {...a11yProps(0)} active={value === 0}>
                Beginner
              </Tab>
              <Tab onClick={() => setValue(1)} {...a11yProps(1)} active={value === 1}>
                Intermediate
              </Tab>
              <Tab onClick={() => setValue(2)} {...a11yProps(2)} active={value === 2}>
                Advanced
              </Tab>
            </Tabs>
          </TabsContainer>
        </ButtonsColumn>

        <ContentColumn>
          <TabPanel value={value} index={0}>
            <h3>Beginner</h3>
            <p>
              Start your trading journey with a solid foundation. Learn basic concepts
              such as market types, trading platforms, and essential trading tools.
            </p>
            <ul>
              <li>Understanding market trends and patterns</li>
              <li>Getting familiar with trading terminology</li>
              <li>Exploring demo trading accounts</li>
            </ul>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <h3>Intermediate</h3>
            <p>
              Enhance your skills by mastering risk management, technical analysis,
              and developing personalized trading strategies.
            </p>
            <ul>
              <li>Using indicators like RSI, MACD, and moving averages</li>
              <li>Managing risk with stop-loss and take-profit orders</li>
              <li>Creating a disciplined trading routine</li>
            </ul>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <h3>Advanced</h3>
            <p>
              Dive into advanced techniques, algorithmic trading, and explore global
              markets to maximize your potential.
            </p>
            <ul>
              <li>Understanding derivatives and futures trading</li>
              <li>Leveraging automated trading systems</li>
              <li>Executing advanced chart pattern analysis</li>
            </ul>
          </TabPanel>
        </ContentColumn>
      </GridContainer>

      <Contact>
      <Headers  >Still have doubts? <span style={{color:'rgb(120, 77, 204)'}}>We've got you Covered.</span></Headers>
      <Buttons>
                  <button   onClick={() => window.location.href = "https://t.me/tradesimple45"}
 className={`button-prim `}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                     Contact Us
                    </div>
                  </button>
                  <button   onClick={() => window.location.href = "./#plans"}
 className={`button-prim `}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      View Plans
                    </div>
                  </button>
                </Buttons>
      </Contact>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  font-family: 'Arial', sans-serif;
  padding: 40px 20px 60px;
  background: #E7ECF9;

`;

const Contact = styled.div`
  width: 80%;
  font-family: 'Arial', sans-serif;
  padding: 30px;
  background: #fff;
  margin:50px auto 0px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); 

  @media (max-width: 768px) {
    width: 98%;
    margin:30px auto 0px;

  }
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.5fr 3fr;
  gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const DescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: none;


  }
`;

const ContentColumn = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);`;

const Header = styled.h1`
  text-align: left;
  font-size: 2rem;
  font-family:var(--font-bold);
  margin-bottom: 20px;
  color: #333;
`;

const Headers = styled.h1`
  text-align: center;
  width:45%;
  margin:0px auto;
  font-size: 2rem;
  font-family:var(--font-bold);
  margin-bottom: 20px;
  color: #333;
  @media (max-width: 768px) {
    font-size: 18px;
    width:100%;

  }
`;

const TabsContainer = styled.div`
  margin-bottom: 20px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction:column;
  gap: 10px;
`;

const Tab = styled.button<{ active: boolean }>`
  padding: 12px 24px;
  background: ${({ active }) => (active ? 'linear-gradient(145deg, rgb(123, 59, 202), rgb(168, 91, 242))' : '#d8d8d8')};
  color: ${({ active }) => (active ? '#fff' : '#333')};
  border: none;
  border-radius:5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background 0.3s ease, transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }
`;

const PanelWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;

const TabContent = styled.div`
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #3f0063;
  }

  p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 20px;
    list-style-type: disc;
    color: #555;

    li {
      margin-bottom: 8px;
    }
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content:center;
  column-gap: 20px;

  font-size: 55px;

  a {
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: transparent;
    color: #ffba00;
    font-size: 18px;

    border: 1px solid #ffba00;
    border-radius: 5px;
    text-align: center;
  }
  @media (max-width: 768px) {
    display: grid;

    grid-template-columns: 1fr;
    a {
      width: 100%;
      padding-top: 9px;
      padding-bottom: 9px;
      background-color: transparent;
      color: #ffba00;
      font-size: 18px;
      border: 1px solid #ffba00;
      border-radius: 5px;
      text-align: center;
    }
  }
`;


export default TradingLevels;
