import React from 'react';
import styled from 'styled-components';

const TermsWrapper = styled.div`
  margin: 50px auto;
  max-width: 90%;
  padding: 20px;
  color: #000;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffba00;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Section = styled.div`
  margin-bottom: 25px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #666666;

  strong {
    color: #ffba00;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const TermsOfUse: React.FC = () => {
  return (
    <TermsWrapper>
      <Paragraph>
      Hi, I’m Jagbir Singh. I moved to Canada as an international student, eager to build a successful future. Like many others, I worked hard to get ahead in life, but after spending long hours working, I realized that I had no time left for myself. This realization sparked a shift in my mindset and led me to explore day trading as a way to regain control over my time and financial independence.<br/><br/>

I started my journey by trading stocks, and as I gained experience, I expanded into options trading. Over the years, my focus has shifted to futures trading, and with 6 years of experience, I’ve developed a deep understanding of the markets. Along the way, I’ve built a trading strategy that allows me to navigate various market conditions with confidence and consistency.<br/><br/>

Trading has not only provided me with the freedom I craved but also the opportunity to build a career around something I’m passionate about. I know firsthand how challenging it can be to balance a demanding job with personal goals, which is why I’m so dedicated to helping others find success in the markets.<br/><br/>

In my trading course, I share everything I’ve learned over the years, from the basics to advanced strategies that can help you achieve financial independence. My approach is built on discipline, a strong mindset, and a deep understanding of market behavior. Whether you’re just starting out or looking to refine your skills, I’m here to guide you every step of the way.<br/><br/>

My ultimate goal is to empower you with the tools and knowledge you need to become a successful trader and live life on your own terms. Let’s get started and take the first step toward a more flexible and fulfilling future.
        </Paragraph>
    </TermsWrapper>
  );
};

export default TermsOfUse;
