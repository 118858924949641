import React, { useEffect, useState } from "react";
import "./Hero.scss";
import Trade from "../../assets/images/image_trade.png"
import styled,{ keyframes } from "styled-components";
import Progress from "./Progress"




const Main = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  .shield {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .poko-shield {
      padding: 40px;
      
      background-color: rgba(38, 38, 38, 0.4); /* #262626 with 10% opacity */
      border-radius: 20px;
      backdrop-filter: blur(1px); /* Apply blur effect */

      border: 1px solid #606060;
      @media (max-width: 768px) {
        padding: 20px;
        width:100%;

      }
    }

    .timer {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .time-block {
      background-color: rgba(38, 38, 38, 0.6); /* #262626 with 10% opacity */
      border-radius: 8px;
      padding: 10px;
      text-align: center;
      width: 80px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        width: 40px;

      }
    }

  

    .time-block span:first-child {
      font-size: 2rem;
      font-weight: bold;
      display: block;

      @media (max-width: 768px) {
        font-size: 1rem;

      }
    }

    @media (max-width: 600px) {
     

      .time-block {
        margin-bottom: 10px;
      }
    }

    h1 {
      text-align: center;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        font-size: 20px;

      }
    }

    img {
      width: 100%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .texture {
    height: 100%;
    display: flex;
    padding-left: 70px;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      padding-left: 10px;
      padding-top: 100px;
    }

    h1 {
      font-size: 57px;
      font-weight: 800;
      font-family: var(--font-bold);
      color: #000;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      color: #a0a0a0;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
`;

const Buttons = styled.div`
  width: 80%;
  display: flex;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  font-size: 55px;

  a {
    width: 100%;
    padding-top: 9px;
    padding-bottom: 9px;
    background-color: transparent;
    color: #ffba00;
    font-size: 18px;

    border: 1px solid #ffba00;
    border-radius: 5px;
    text-align: center;
  }
  @media (max-width: 768px) {
    display: grid;

    grid-template-columns: 1fr;
    a {
      width: 100%;
      padding-top: 9px;
      padding-bottom: 9px;
      background-color: transparent;
      color: #ffba00;
      font-size: 18px;
      border: 1px solid #ffba00;
      border-radius: 5px;
      text-align: center;
    }
  }
`;

const Hero: React.FC = () => {
  const [bnbPrice, setBnbPrice] = useState(""); // Placeholder for BNB price
  const [raisedBNB, setRaisedBNB] = useState(""); // Placeholder for BNB price


  const [checked, setChecked] = React.useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  
  
  // Simulate token calculation (replace with actual logic)

  async function fetchBscPairData(pairAddress:string) {
    const apiUrl = `https://openapi.dexview.com/latest/dex/pairs/bsc/${pairAddress}`;
    
    try {
      const response = await fetch(apiUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }
      
      const data = await response.json();
  
      // Extract relevant data from the response
      if (data && data.pair) {
        const { baseToken, quoteToken, priceNative, priceUsd, liquidity, txns, volume } = data.pair;
  
  
        setBnbPrice(priceUsd)
  

      } else {
      }
    } catch (error:any) {
    }
  }
  
  // Usage: Pass the pair address as an argument
  fetchBscPairData("0x36696169c63e42cd08ce11f5deebbcebae652050");

  
  
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("2025-02-01T18:40:00Z").getTime();

    const updateTimer = () => {
      const now = new Date();
      const timeDifference = targetDate - now.getTime();

      if (timeDifference <= 0) {
        setTimeLeft({ days: 0, hours:0 , minutes: 0, seconds: 0 });
        clearInterval(intervalId);
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    const intervalId = setInterval(updateTimer, 1000);
    updateTimer(); // Initial call to set time immediately

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="hero-wrapper">
        <div className="box-wrapper">
          {" "}
          {/* Added 'waves-background' class */}
          <Main>
            <div className="texture">
              <div>
                <h1>
                  Trading For{" "}
                  <span style={{ color: "#ffba00" }}>Anyone.<br/>Anywhere. Anytime</span>
                </h1>
                <p>
                We revolutionize trading with our intuitive and user-friendly strategies, designed to make every trade simple and effective.
                </p>
                <Buttons>
                <button   onClick={() => window.location.href = "https://users.tradesimple.ca/signup"}
 className={`button-prim `}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      Create Account
                    </div>
                  </button>
                  <button   onClick={() => window.location.href = "https://users.tradesimple.ca/"}
 className={`button-prim `}>
                    <div
                      style={{
                        display: "flex",
                        fontSize: 16,
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      View Dashboard
                    </div>
                  </button>
                </Buttons>
              </div>
            </div>
            <div className="shield">
       <img src={Trade} style={{width:'90%'}}/>
            </div>



          </Main>
        </div>
    
    </div>
  );
};

export default Hero;
