import React, { useState } from "react";
import {FaTelegram as FaWhatsapp } from "react-icons/fa";
import "./TelegramIcon.css";
import { FaQuestionCircle } from "react-icons/fa";

const TelegramIcon: React.FC = () => {
  const [showText, setShowText] = useState(false);
  const [showTexts, setShowTexts] = useState(false);

  return (
<>
    <div
    className="telegram-icon-containers"
    onMouseEnter={() => setShowTexts(true)}
    onMouseLeave={() => setShowTexts(false)}
  >
    <a
      href="https://users.tradesimple.ca/faqs" // Replace with your Telegram link
      target="_blank"
      rel="noopener noreferrer"
      className="telegram-icons"
    >
      <FaQuestionCircle size={30} />
    </a>
    <div className={`animated-texts ${showTexts ? "visible" : ""}`}>
      Trade Simple FAQs
    </div>
  </div>
    <div
      className="telegram-icon-container"
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <a
        href="https://t.me/tradesimple45" // Replace with your Telegram link
        target="_blank"
        rel="noopener noreferrer"
        className="telegram-icon"
      >
        <FaWhatsapp size={30} />
      </a>
      <div className={`animated-text ${showText ? "visible" : ""}`}>
        Join Trade Simple Support Group !
      </div>
    </div>
    </>
  );
};

export default TelegramIcon;
