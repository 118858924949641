import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./page/Home";
import Hiring from "./page/Hiring";
import { useSnackbar, SnackbarProvider } from "notistack";
import Privacy from "./page/PrivacyPolicy"
import PrivacyPolicy from "./page/About"
import TelegramIcon from "./components/TelegramIcon"; // Adjust the path as necessary

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Cookie from "./page/CookiePolicy"




const App: React.FC = () => {
  return (
    <>
      <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
  >
      <Navigation />
      <div className="layout-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="terms-of-use" element={<Privacy />} />
          <Route path="disclaimer" element={<Cookie />} />
          <Route path="about" element={<PrivacyPolicy />} />



        </Routes>
      </div>
      <Footer />
      <TelegramIcon/>
      </SnackbarProvider>
    </>
  );
};

export default App;
