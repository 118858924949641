import React from 'react';
import styled from 'styled-components';

import customer from '../../assets/images/details.svg';
import elements from '../../assets/images/teacher.svg';
import bitcoin from '../../assets/images/money.svg';
import crown from '../../assets/images/quality.svg';

const MainWrapper = styled.div`
  max-width: 100%;
  border-radius: 20px;
  padding:50px 20px;


  h1 {
    text-align: center;
    color: #000;
    font-weight: 500;
    font-size: 45px;
    font-family: var(--font-bold);
    @media (max-width: 768px) {
      font-size: 22px;
    }
  }

  p {
    text-align: center;
    font-size: 17px;
    color: #a0a0a0;
    margin: 0 30%;

    @media (max-width: 768px) {
      margin: 0;
      font-size: 13px;
    }
  }
`;

const Audits = styled.div`
  display: grid;
  margin-top: 20px;
  width: 100%;
  padding: 20px 200px;
  grid-template-columns: 1fr 1fr;
  gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding: 20px 0px;
  }

  .auditkyc {
    background: linear-gradient(90deg, rgba(144, 109, 255, 0.2), rgba(160, 85, 240, 0.2));
        border-radius: 15px;
    border: 2px solid rgb(160, 85, 240);
    padding: 35px;
    @media (max-width: 768px) {
      padding: 25px;

    }

    .icon-container {
      width: fit-content;
      display: flex;
      border: 1px solid rgb(160, 85, 240);

      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 60px;
      height: 60px;
      margin: 0;
      @media (max-width: 768px) {
        width: 30px;
        height: 30px;      }
    }

    h1 {
      font-size: 26px;
      font-weight: 600;
      font-family: var(--font-normal);
      text-align: left;
      color:#3f0063;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    p {
      padding: 0;
      margin: 0;
      color:rgb(102, 102, 102);
      text-align: left;
      font-size: 14px;
      margin-top: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const BlockThree: React.FC = () => {
  const auditData = [
    {
      image: customer,
      title: "In-depth Market Analysis",
      description:
        "Our course offers comprehensive market analysis tools and techniques to help you understand market trends and make informed trading decisions. You'll be able to identify opportunities and risks more accurately.",
    },
    {
      image: bitcoin,
      title: "Tailored Learning Path",
      description:
        "The course is designed to fit your learning style and pace, ensuring that whether you’re a beginner or an advanced trader, you get the best guidance to reach your goals.",
    },
    {
      image: elements,
      title: "Expert Instructors",
      description:
        "Our instructors are seasoned professionals with years of trading experience in the Futures market. They bring real-world insights and actionable strategies to help you excel in trading.",
    },
    {
      image: crown,
      title: "Affordable Investment",
      description:
        "We offer competitive pricing for the value-packed lessons you’ll receive. Compare us with other platforms, and you’ll see that we deliver top-notch content at a fraction of the price.",
    },
  ];

  return (
    <div className="blockthree-wrapper apoplp" style={{ margin: 0 }}>
      <MainWrapper>
        <h1>
          Why Choose <span style={{ color: '#784dcc' }}>Trade Simple?</span>
        </h1>
        <p>
        Effortless trading made simple with smart tools and seamless strategies.        </p>
        <Audits>
          {auditData.map((audit, index) => (
            <div className="auditkyc" key={index}>
              <div className="icon-container">
                <img src={audit.image} alt={audit.title} />
              </div>
              <h1>{audit.title}</h1>
              <p>{audit.description}</p>
            </div>
          ))}
        </Audits>
      </MainWrapper>
    </div>
  );
};

export default BlockThree;
